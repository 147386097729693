import { createSelector } from 'reselect';

const selectPartDomain = state => state.app;

const makeSelectObjectStatusList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('objectStatusList') : []
  );

const makeSelectUOMList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('uomList') : []
  );

const makeSelectCountryList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('countryList') : []
  );

const makeSelectTenantCommon = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenantCommon') : {}
  );


const makeSelectTenantUser = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenantUser') : {}
  );

const makeSelectUserAccess = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('userAccess') : {}
  );

const makeSelectCommonSearchData = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('commonSearchData') : {}
  );

const makeSelectAppBillingTermsList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('billingTermsList') : []
  );


const makeSelectAppPackagesList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('appPackageList') : []
  );

const makeSelectPublicCalendar = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('publicCalendar') : []
  );


const makeSelectAppUOMList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('appUomList') : []
  );

const makeSelectUOMConvesionFactorList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('uomConversionFactorList') : []
  );

const makeSelectDateFormatList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('dateFormatList') : []
  );

export {
  makeSelectObjectStatusList,
  makeSelectUOMList,
  makeSelectCountryList,
  makeSelectTenantCommon,
  makeSelectTenantUser,
  makeSelectUserAccess,
  makeSelectCommonSearchData,
  makeSelectAppBillingTermsList,
  makeSelectAppPackagesList,
  makeSelectPublicCalendar,
  makeSelectAppUOMList,
  makeSelectUOMConvesionFactorList,
  makeSelectDateFormatList
}
