import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import './NavBar.css';
import SideBarFull from './SideBarFull';
import SideBarIcon from './SideBarIcon';
import Logo from '../../../images/WELAWA_White.png';
import UserMenu from './UserMenu';
import { saveLoginUser } from '../../../store/action/authAction';
import User from '../../../images/user.png';
import Image from 'react-bootstrap/Image';
import * as actions from '../../../store/action/navBarAction';
import * as selectors from '../../../store/selector/navBarSelector';
import { makeSelectTenantUser } from '../../../store/selector/selector';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Cookies from 'js-cookie';
import CustomMenu from './CustomMenu';
import { makeSelectUserAccessSuccess } from '../../../store/selector/usersSelector';
import { makeSelectTenantBasic } from '../../../store/selector/tenantSelector';

class Navbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      settingMenu: false,
      userMenu: false
    };
  };

  componentDidMount() {
    this.props.dispatch(actions.saveActiveRoute(window.location.hash))
  }

  showSidebar = () => {
    const sidebarStaus = !this.state.sidebar;
    this.setState({ sidebar: sidebarStaus });
    this.props.dispatch(actions.saveIsSideBarStatus(sidebarStaus));
  }

  showSettingMenu = () => {
    const settingMenuStatus = this.state.settingMenu;
    this.setState({ settingMenu: !settingMenuStatus })
  }

  settingMenuClose = () => {
    this.setState({
      settingMenu: false
    })
  }

  handleSignOut = () => {
    Cookies.remove("user")
    this.props.dispatch(saveLoginUser(false))
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }

  showUserMenu = () => {
    const userMenuStatus = this.state.userMenu;
    this.setState({ userMenu: !userMenuStatus })
  }

  userMenuClose = () => {
    this.setState({
      userMenu: false
    })
  }

  redirectProject = () => {
    this.props.dispatch(actions.saveActiveRoute('#/project'))
  }

  redirectDashboard = () => {
    this.props.dispatch(actions.saveActiveRoute('#/dashboard'))
  }

  redirectCustomer = () => {
    this.props.dispatch(actions.saveActiveRoute('#/customer'))
  }

  redirectReport = () => {
    this.props.dispatch(actions.saveActiveRoute('#/revenueForcasting'))
  }

  redirectTimesheet = () => {
    this.props.dispatch(actions.saveActiveRoute('#/timesheet'))
  }

  redirectEmployee = () => {
    this.props.dispatch(actions.saveActiveRoute('#/employee'))
  }

  render() {
    const { activeRoute, tenantUser, userAccess,
      // tenantDetails 
    } = this.props;
    return (
      <div>
        <header>
          <div className="nav_left" >
            <div className="navlogo">
              <img alt="vemaslogo" id="navlogo" style={this.state.sidebar ? { width: '5%' } : { width: '70px' }} src={Logo} />
            </div>
            <div className="sidebar-menu" onClick={this.showSidebar}>
              <ui5-icon class="samples-margin" name="menu2" style={this.state.sidebar ? { marginLeft: '-7000%' } : { margin: '20px' }} id="icon-menu"></ui5-icon>
            </div>
          </div>
          <div className='nav-center'></div>
          <div className="nav_right">
            <div onClick={this.showSettingMenu} id="settingMenu">
              <ui5-icon class="samples-margin" name="settings" id="icon"></ui5-icon>
              {this.state.settingMenu &&
                <CustomMenu
                  settingMenuClose={this.settingMenuClose}
                  userDisplayAccess={userAccess && userAccess.displayUser}
                  userAccess={userAccess}
                />
              }
            </div>
            <div onClick={this.showUserMenu}>
              <Image roundedCircle className="userImage" src={tenantUser && tenantUser.id && tenantUser.logo ? tenantUser.logo : User} />
              {this.state.userMenu &&
                <UserMenu
                  handleSignOut={this.handleSignOut}
                  userMenuClose={this.userMenuClose}
                  tenantImage={tenantUser && tenantUser.id ? tenantUser.logo : ''}
                  tenantName={tenantUser && tenantUser.id ? tenantUser.firstName + " " + tenantUser.lastName : ''}
                />}
            </div>
          </div>
        </header>
        {this.state.sidebar ?
          <SideBarFull
            redirectDashboard={this.redirectDashboard}
            redirectProject={this.redirectProject}
            redirectTimesheet={this.redirectTimesheet}
            activeRoute={activeRoute}
            redirectEmployee={this.redirectEmployee}
            redirectCustomer={this.redirectCustomer}
            redirectReport={this.redirectReport}
            userAccess={userAccess}
          /> :
          <SideBarIcon
            redirectDashboard={this.redirectDashboard}
            redirectProject={this.redirectProject}
            redirectTimesheet={this.redirectTimesheet}
            activeRoute={activeRoute}
            redirectEmployee={this.redirectEmployee}
            redirectCustomer={this.redirectCustomer}
            redirectReport={this.redirectReport}
            userAccess={userAccess}
          />
        }
      </div>
    )
  }
}

Navbar.propTypes = {
}

const mapStateToProps = createStructuredSelector({
  activeRoute: selectors.makeSelectActiveRoute(),
  tenantUser: makeSelectTenantUser(),
  userAccess: makeSelectUserAccessSuccess(),
  tenantDetails: makeSelectTenantBasic(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Navbar));